import O from "../../components/Option";
import {PointTypeEnum} from "./Point";


export function getText(value) {
    const option = this.find(o => o.value === value);
    return option ? option.text : value;
};

export const WeekDayOptions = [
    new O(1, "base_enum_monday"),
    new O(2, "base_enum_tuesday"),
    new O(3, "base_enum_wednesday"),
    new O(4, "base_enum_thursday"),
    new O(5, "base_enum_friday"),
	new O(6, "base_enum_saturday"),
	new O(0, "base_enum_sunday"),
];
WeekDayOptions.getText = getText;

export const CutoffDayOptions = [
    new O(0, "client_enum_d0"),
    new O(1, "client_enum_d1"),
    new O(2, "client_enum_d2"),
    new O(3, "client_enum_d3"),
    new O(4, "client_enum_d4"),
    new O(5, "client_enum_d5"),
];

CutoffDayOptions.getText = getText;

export const TypeAssemblyOptions = [
    new O(1, "waybills_enum_in-bulk"),
    new O(2, "waybills_enum_cured")
];

export const CargoTypeOptions = [
    new O(10, "waybills_enum_alco"),
    new O(20, "waybills_enum_cosm"),
    new O(30, "waybills_enum_food"),
    new O(40, "waybills_enum_nonf"),
    new O(50, "waybills_enum_moda"),
    new O(60, "waybills_enum_farm"),
    new O(70, "waybills_enum_adr"),
];
CargoTypeOptions.getText = getText;

export const ThermoModeOptions = [
    new O(10, "waybills_enum_tent"),
    new O(20, "waybills_enum_isotherm"),
    /*new O(30, "Реф (t+16+18)"),
    new O(40, "Реф (t+5+25)"),
    new O(50, "Реф (t+5+8)"),*/
    new O(60, "waybills_enum_r20c"),
    new O(70, "waybills_enum_ref-t-14-18"),
    new O(80, "waybills_enum_ref-t-2-6"),
];

export const RequestTypeEnum = {
    STOCK: 10,
    STOCKACTION: 11,
    TRANSIT: 20,
    TRANSITACTION: 21,
    WHLSALE: 30,
    WHLSALEACTION: 31,
    RETAIL: 40,
    RETAILACTION: 41,
    ACTION: 80
};

export const RequestTypeOptions = [
    new O(RequestTypeEnum.STOCK, "waybills_enum_stock"),
    new O(RequestTypeEnum.STOCKACTION, "waybills_enum_stock-acion"),
    new O(RequestTypeEnum.TRANSIT, "waybills_enum_transit"),
    new O(RequestTypeEnum.TRANSITACTION, "waybills_enum_transit-action"),
    new O(RequestTypeEnum.WHLSALE, "waybills_enum_whlsale"),
    new O(RequestTypeEnum.WHLSALEACTION, "waybills_enum_whlsale-action"),
    new O(RequestTypeEnum.RETAIL, "waybills_enum_retail"),
    new O(RequestTypeEnum.RETAILACTION, "waybills_enum_retail-action"),
    new O(RequestTypeEnum.ACTION, "waybills_enum_action")
];

export const PackTypeEnum = {
    BT: "BT",
    BX: "BX",
    EP: "EP",
    //LP: "LP", Выпилен из бекенда!!!
    OT: "OT",
    PP: "PP",
    HP: "HP",
    LB: "LB"
};

export const PackTypeOptions = [
    new O(PackTypeEnum.EP, "waybills_enum_eur"),
    new O(PackTypeEnum.BT, "waybills_enum_fin"),
    new O(PackTypeEnum.HP, "waybills_enum_amer"),
    new O(PackTypeEnum.OT, "waybills_enum_oversize-pallets"),
    new O(PackTypeEnum.BX, "waybills_enum_boxes"),
    new O(PackTypeEnum.LB, "waybills_enum_large-boxes"),
    new O(PackTypeEnum.PP, "waybills_enum_empty-pallets-up-to-15"),
];

export const PackTypeDisplayOptions = [
    ...PackTypeOptions,
    new O("EP", "waybills_enum_eur")
];

export const WaybillPointTypeEnum = {
    LOADING: 0,
    UNLOADING: 1
};

export const PickupTypeEnum =
{
    NOT_SPECIFIED: 0,
    // Пикап FM
    FM: 1,
    // Другая транспортная компания
    OTHER: 5,
    // Уже на кроссдоке
    DELIVERED: 9
};

export const PickupTypeOptions = [
    new O(1, "waybills_enum_transport-fm"),
    new O(5, "waybills_enum_self-delivery"),
    new O(9, "waybills_enum_cross-dock-cargo"),
];

export const WaybillFieldEnum = {
    ShippingType: 1,
    ShippingTempCondition: 2,
    CargoType: 3,
    CargoUnitType: 4,
    VehicleCapacity: 5,
    CarType: 6,
    Provider: 7,
    BodyCapacity: 8,
};

export const WaybillFieldOptions = [
    new O(WaybillFieldEnum.ShippingType, "waybills_enum_app-type"),
    new O(WaybillFieldEnum.ShippingTempCondition, "waybills_enum_temp-condition"),
    new O(WaybillFieldEnum.CargoType, "waybills_enum_cargo-type"),
    new O(WaybillFieldEnum.CargoUnitType, "waybills_enum_cargo-units-type"),
    new O(WaybillFieldEnum.VehicleCapacity, "waybills_enum_capacity-ftl"),
    new O(WaybillFieldEnum.BodyCapacity, "waybills_enum_body-volume"),
    new O(WaybillFieldEnum.Provider, "waybills_enum_provider"),
];

export const CompanyTypeOptions = [
    new O(PointTypeEnum.RECEPIENT, "waybills_enum_contactor"),
    new O(PointTypeEnum.CLIENT_WAREHOUSE, "waybills_enum_client-warehouse"),
    new O(PointTypeEnum.FM, "waybills_enum_cross-dock"),
];

CompanyTypeOptions.getText = getText;

export const WaybillCutoffShipmentRegionsEnum = {
	MM: 0,
	MR: 1,
	RM: 2,
	RR: 3
};
export const WaybillCutoffShipmentRegionsEnumOptions = [
    new O(WaybillCutoffShipmentRegionsEnum.MM, "waybills_enum_mm"),
    new O(WaybillCutoffShipmentRegionsEnum.MR, "waybills_enum_mr"),
    new O(WaybillCutoffShipmentRegionsEnum.RM, "waybills_enum_rm"),
    new O(WaybillCutoffShipmentRegionsEnum.RR, "waybills_enum_rr")
];
WaybillCutoffShipmentRegionsEnumOptions.getText = getText;
